<template>
  <router-view/>
  <Footer></Footer>
</template>
<script>
import Footer from './Footer'

export default {
  name: 'BasicLayout',
  components: {
    Footer
  }
}
</script>
    