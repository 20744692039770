import { createRouter, createWebHistory } from 'vue-router'
import AppLayout from '../components/Layout/AppLayout'
import LoginLayout from '../components/Layout/LoginLayout'
import SignupLayout from '../components/Layout/SignupLayout'
import SplashLayout from '../components/Layout/SplashLayout'
import BasicLayout from '../components/Layout/BasicLayout.vue'

const routes = [
  {
    path: '/',
    name: 'AppTemplate',
    component: AppLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () =>
          import(/* webpackChunkName: 'LandingPage' */ '../views/Home'),
        meta: {
          title: 'Circleram | Home',
          isPublic: true,
        },
      },
      {
        path: '/home2',
        name: 'home2',
        component: () =>
          import(/* webpackChunkName: 'HomePage2' */ '../views/LandingPage'),
        meta: {
          title: 'Circleram | Home',
          isPublic: true,
        },
      },
      {
        path: '/policy',
        name: 'policy',
        component: () =>
          import(/* webpackChunkName: 'policy' */ '../views/Policy.vue'),
        meta: {
          title: 'Course Manager | Portal',
          isPublic: true,
        },
      },
      {
        path: '/pricing',
        name: 'pricing',
        component: () =>
          import(/* webpackChunkName: 'PricingPage' */ '../views/PricingPage.vue'),
        meta: {
          title: 'Course Manager | Pricing',
          isPublic: true,
        },
      },
      
      {
        path: '/terms',
        name: 'terms',
        component: () =>
          import(/* webpackChunkName: 'terms' */ '../views/Terms.vue'),
        meta: {
          title: 'Course Manager | Terms & Conditions',
          isPublic: true,
        },
      },
      {
        path: '/accessibility',
        name: 'accessibility',
        component: () =>
          import(/* webpackChunkName: 'policy' */ '../views/Accessibility.vue'),
        meta: {
          title: 'Course Manager | Portal',
          isPublic: true,
        },
      },
      {
        path: '/accessibility-details',
        name: 'accessibility-details',
        component: () =>
          import(/* webpackChunkName: 'policy' */ '../views/Accessibility-details.vue'),
        meta: {
          title: 'Course Manager | Portal',
          isPublic: true,
        },
      },
      {
        path: '/portal',
        name: 'portal',
        component: () =>
          import(/* webpackChunkName: 'portal' */ '../views/PortalPage.vue'),
        meta: {
          title: 'Course Manager | Portal',
          isPublic: true,
        },
      },
      {
        path: '/services',
        name: 'services',
        component: () =>
          import(/* webpackChunkName: 'services' */ '../views/ServicesPage'),
        meta: {
          title: 'Course Manager | Services',
          isPublic: true,
        },
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
          import(/* webpackChunkName: 'Dashboard' */ '../views/DashboardPage'),
        meta: {
          title: 'Course Manager | Dashboard',
          isPublic: false,
        },
      },
      {
        path: '/shop',
        name: 'Shop-Root',
        component: () =>
          import(
            /* webpackChunkName: 'PassThrough' */ '../../../daoSource/components/general/PassThrough'
          ),
        children: [
          {
            path: 'request',
            component: () =>
              import(
                /* webpackChunkName: 'PassThrough' */ '../../../daoSource/components/general/PassThrough'
              ),
            redirect: { name: 'request-for-shop' },
            children: [
              {
                path: '',
                name: 'request-for-shop',
                component: () =>
                  import(
                    /* webpackChunkName: 'RequestForShop' */ '../views/RequestForShopPage'
                  ),
                meta: {
                  title: 'Course Manager | Request for a shop',
                  isPublic: false,
                },
              },

              // {
              //   path: ':RequestId/timeline',
              //   name: 'RequestTimeline',
              //   component: () =>
              //     import(
              //       /* webpackChunkName: 'ShopRequestTimeline' */ '../../../daoSource/components/Timeline_MOMSITE'
              //     ),
              //   meta: {
              //     title: 'Course Manager | Request Timeline',
              //     isPublic: false,
              //   },
              // },
              {
                path: ':RequestId/timeline-reseller',
                name: 'RequestTimeline',
                component: () =>
                  import(
                    /* webpackChunkName: 'ShopRequestTimeline' */ '../views/Timeline_reseller'
                  ),
                meta: {
                  title: 'Course Manager | Request Timeline',
                  isPublic: false,
                },
              },
            ],
          },
          {
            path: ':ShopId/pricing-details',
            name: 'Pricing-Details',
            component: () =>
              import(
                /* webpackChunkName: 'PricingDetails' */ '../views/PricingDetails'
              ),
            meta: {
              title: 'Course Manager | Pricing Details',
              isPublic: false,
            },
          },
          // {
          //   path: ':ShopId/pricing-model',
          //   name: 'Pricing-Model',
          //   component: () =>
          //     import(/* webpackChunkName: 'PricingModel' */ '../views/PricingModel'),
          //   meta: {
          //     title: 'Course Manager | Pricing Model',
          //     isPublic: false
          //   },
          // },
        ],
      },
      {
        path: 'setting',
        component: () =>
          import(
            /* webpackChunkName: 'PassThrough' */ '../../../daoSource/components/general/PassThrough'
          ),
        children: [
          {
            path: 'payment',
            name: 'payment-setup',
            component: () =>
              import(
                /* webpackChunkName: 'PaymentAndBillingSetup' */ '../views/PaymentSetup'
              ),
            meta: {
              title: 'Course Manager | Payment & Billing Setup',
              isPublic: false,
            }
          },
          {
            path: 'billing-and-payout',
            name: 'billing-and-payout-setup',
            component: () =>
              import(
                /* webpackChunkName: 'BillingAndPayoutSetup' */ '../views/BillingAndPayoutSetup'
              ),
            meta: {
              title: 'Course Manager | Billing & Payout Setup',
              isPublic: false,
            }
          },
          {
            path: 'stripe-payment-profile/:mode/:plan?',
            name: 'stripe-payment-profile',
            component: () =>
              import( /* webpackChunkName: 'StripePaymentProfileRedirect' */ '../views/redirections/StripePaymentSetup'),
            meta: {
              title: 'Course Manager | Redirecting to stripe',
              isPublic: false,
            }
          }
        ],
      },
      {
        path: '/birthing-centers',
        component: () =>
          import( /* webpackChunkName: 'PassThrough' */ '../../../daoSource/components/general/PassThrough'),
        redirect: { name: 'birthing-center-list' },
        children: [
          {
            path: '',
            name: 'birthing-centers',
            component: () =>
              import(
                /* webpackChunkName: 'BirthCenters' */ '../views/BirthCenters'
              ),
            meta: {
              title: 'Course Manager | BirthCenters',
              isPublic: true,
            },
          },
          {
            path: ':searchKey',
            name: 'birthing-center-search',
            component: () =>
              import(
                /* webpackChunkName: 'BirthCenters' */ '../views/BirthCenters'
              ),
            meta: {
              title: 'Course Manager | BirthCenters',
              isPublic: true,
            },
          }
        ],
      },
      {
        path: '/add-birthing-center',
        name: 'add-birthing-center',
        component: () =>
          import(
            /* webpackChunkName: 'AddBirthingCenter' */ '../views/AddBirthingCenter'
          ),
        meta: {
          title: 'Course Manager | Add Birthing Center',
          isPublic: true,
        },
      },
      {
        path: '/engage',
        name: 'engage',
        component: () =>
          import(/* webpackChunkName: 'Engage' */ '../views/Engage'),
        meta: {
          title: 'Course Manager | Engage',
          isPublic: true,
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: () =>
          import(
            /* webpackChunkName: 'Profile' */ '../../../daoSource/components/ProfilePage'
          ),
        meta: {
          title: 'Course Manager | Profile',
          isPublic: false,
        },
      },
      {
        path: '/verify-email/:encryptedURL(.*)',
        name: 'EmailVerification',
        component: () =>
          import(/* webpackChunkName: 'EmailVerification' */ '../views/EmailVerification'),
        meta: {
          title: 'Course Manager | Verification',
          isPublic: true,
        },
      }
    ],
  },
  {
    path: '/splash',
    name: 'SplashTemplate',
    component: SplashLayout,
    children: [
      {
        path: '',
        name: 'splash',
        component: () =>
          import(/* webpackChunkName: 'SplashPage' */ '../views/SplashPage'),
        meta: {
          title: 'Course Manager | Splash',
          isPublic: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'loginTemplate',
    component: LoginLayout,
    children: [
      {
        path: '',
        name: 'login',
        component: () =>
          import(/* webpackChunkName: 'login' */ '../views/LoginPage'),
        meta: {
          title: 'Circaleram | Login',
          isPublic: true,
        },
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () =>
          import(/* webpackChunkName: 'login' */ '../views/ForgotPassword'),
        meta: {
          title: 'Course Manager | Forgot Password',
          isPublic: true,
        }
      },
      {
        path: '/reset-password/:recoveryId',
        name: 'reset-password',
        component: () =>
          import(/* webpackChunkName: 'login' */ '../views/ResetPasswordView'),
        meta: {
          title: 'Course Manager | Reset Password',
          isPublic: true,
        }
      }
    ],
  },
  {
    path: '/signup',
    name: 'SignupTemplate',
    component: SignupLayout,
    children: [
      {
        path: '',
        name: 'signup',
        component: () =>
          import(/* webpackChunkName: 'signup' */ '../views/SignupPage'),
        meta: {
          title: 'Circaleram | Signup',
          isPublic: true,
        },
      },
    ],
  },
  {
    path: '/oauth',
    name: 'oauth',
    component: () =>
      import(/* webpackChunkName: 'OAuth' */ '../views/OAuth.vue'),
    meta: {
      title: 'Course Manager | OAuth',
      isPublic: true,
    },
  },
  {
    path: '/business',
    name: 'business',
    component: BasicLayout,
    children: [
      {
        path: 'dance-school',
        name: 'dance-school',
        component: () =>
          import(/* webpackChunkName: 'DanceSchoolPromotion' */ '../views/PromotionalPage/DanceSchoolPromotion.vue'),
        meta: {
          title: 'Circaleram | Business',
          isPublic: true,
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
